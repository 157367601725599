<template>
  <div>
    <div style="display: flex; justify-content: center;" v-if="loading">
      <div class="d-flex justify-content-between mx-5 mt-3 mb-5">
        <div class="spinner-border text-success align-self-center loader-lg" />
      </div>
    </div>
    <div v-else-if="loadingError" class="cm-loading-error">
      {{ $t("calendarModule.errors.loadingError") }}
    </div>
    <div v-else>
      <b-modal
        ref="defaultSlotFromModal"
        @hide="defaultSlotFormOpen = false"
        hide-footer
        size="xl"
        :title="
          selectedDefaultSlot
            ? $t('calendarModule.modalTitles.editDefaultSlot')
            : $t('calendarModule.modalTitles.addDefaultSlot')
        "
      >
        <DefaultSlotForm
          v-if="defaultSlotFormOpen"
          :the-calendar="calendar"
          :for-date="defaultSlotFormOpen"
          :the-default-slot="selectedDefaultSlot"
          @slotsAddedOrSaved="defaultSlotsAddedOrSaved"
        />
      </b-modal>
      <b-modal
        ref="slotFormModal"
        size="xl"
        @hide="slotFormOpen = false"
        :title="
          selectedSlot
            ? $t('calendarModule.modalTitles.editSlot')
            : $t('calendarModule.modalTitles.addSlot')
        "
        hide-footer
      >
        <SlotForm
          v-if="slotFormOpen"
          :for-date="slotFormOpen"
          :the-calendar="calendar"
          :the-slot="selectedSlot"
          @slotsAddedOrSaved="slotsAddedOrSaved"
        />
      </b-modal>
      <b-modal
        ref="massModal"
        size="xl"
        @hide="massModalSlots = null"
        :title="$t('calendarModule.modalTitles.massSlotsActions')"
        hide-footer
      >
        <MassSlotsActions
          v-if="massModalSlots"
          :slots="massModalSlots"
          :is-edit="true"
          :edit-in-list="false"
          @slotsChanged="slotsAddedOrSaved"
        />
      </b-modal>

      <div class="cm-range-selector">
        <div class="cm-range-selector-position">
          <div class="cm-range-selector-inner">
            <div @click="prevPeriod" class="cm-action-icon">
              <b-icon-arrow-left-circle-fill />
            </div>
            <div v-if="calendarMode === 'week'" class="cm-range-selector-text">
              {{ fromDate.format("DD.MM") }} {{ formatWeekdays(fromDate) }} -
              {{ toDate.format("DD.MM.") }} {{ formatWeekdays(toDate) }}
            </div>
            <div v-else class="cm-range-selector-text">
              {{ fromDate.format("DD.MM.") }} {{ formatWeekdays(fromDate) }}
            </div>
            <div @click="nextPeriod" class="cm-action-icon">
              <b-icon-arrow-right-circle-fill />
            </div>
          </div>
        </div>
      </div>
      <div class="cm-calendar-hour">
        <div class="cm-calendar-pre">
          <div class="cm-calendar-pre-inner">
            {{ $t("calendarModule.calendarForm.texts.day") }}
          </div>
        </div>
        <!-- moment().format('YYYY-MM-DD') === day.format('YYYY-MM-DD') && (isTabletScreenSize || isMobileScreenSize) ?'cm-calendar-day-th': ''-->
        <div
          class="cm-calendar-day"
          :class="formatCalendarSlot(day)"
          v-for="day of days"
          :key="day.format()"
        >
          <div
            class="currentDayMark"
            v-if="
              moment().format('YYYY-MM-DD') === day.format('YYYY-MM-DD') &&
                !isTabletScreenSize &&
                !isMobileScreenSize
            "
          ></div>
          <div>{{ day.format("DD.MM.") }} {{ formatWeekdays(day) }}</div>
          <div v-if="!isTabletScreenSize && !isMobileScreenSize">
            {{ $t("calendarModule.slotForm.labels.booked") }}:
            {{ takenSlots(getFilteredSlots(day)).length }}/{{
              getFilteredSlots(day).length
            }}
          </div>
        </div>
      </div>

      <div v-for="hour of hours" class="cm-calendar-hour" :key="'hour' + hour">
        <div class="mt-1">
          <div class="cm-calendar-pre-inner">
            {{ formatHourNumber(hour) }}
          </div>
        </div>
        <div
          class="cm-calendar-day"
          v-for="day of days"
          :key="hour + day.format()"
        >
          <b-badge
            v-for="aggregatedSlot of aggregateSlots(
              getFilteredSlots(
                day,
                formatHourNumber(hour),
                formatHourNumber(hour, '59')
              )
            )"
            :key="aggregatedSlot.dateTimeId + 'aggregatedSlot'"
            @click="
              () =>
                openMassModal(
                  day,
                  aggregatedSlot.slots[0].time,
                  aggregatedSlot.slots[0].time
                )
            "
            class="font- size-very-small mr-1 ml-1 cm-aggregated-slots badge-cal-padding"
            :class="
              takenSlots(aggregatedSlot.slots).length > 0
                ? 'bg-warning'
                : (aggregatedSlot.slots[0].metadata.isFollowupSlot ? 'bg-dark' : 'bg-success')
            "
          >
            <img
              v-if="isMobileScreenSize || isModalMobileScreenSize"
              :class="
                `p-2 bg-white ${
                  aggregatedSlot.slots[0].metadata.type === 'gom'
                    ? (aggregatedSlot.slots[0].metadata.isFollowupSlot ? 'feathericons-link' : 'feathericons-users')
                    : 'feathericons-user'
                }`
              "
            />
            <span v-else>
              <img
                :class="
                  `p-2 bg-white ${
                    aggregatedSlot.slots[0].metadata.type === 'gom'
                      ? (aggregatedSlot.slots[0].metadata.isFollowupSlot ? 'feathericons-link' : 'feathericons-users')
                      : 'feathericons-user'
                  }`
                "
              />
              {{ aggregatedSlot.slots[0].time }} -
              {{ aggregatedSlot.slots[0].endTime }}</span
            >
          </b-badge>
          <!-- ({{
              takenSlots(aggregatedSlot.slots).length
            }}/{{ aggregatedSlot.slots.length }}) -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as cmApi from "../../store/modules/calendarModule/calendarModule.api";
import moment from "moment";
import { mapGetters } from "vuex";
import { convertToLocal } from "../../utils/timezoneHelper";
import screenSizeMixin from "@/mixins/screenSize";
export default {
  name: "Calendar",
  mixins: [screenSizeMixin],
  props: [
    "theCalendar", // the calendar object / the calendar ID
    "forDate", // date (optional)
    "calendarMode", // mode: week or day
    "withFloatingRangeSelector"
  ],
  components: {
    MassSlotsActions: () =>
      import("../../components/calendarModule/common/MassSlotsActions"),
    SlotForm: () => import("../../components/calendarModule/forms/SlotForm"),
    DefaultSlotForm: () =>
      import("../../components/calendarModule/forms/DefaultSlotForm")
  },
  data() {
    return {
      moment: moment,
      currentUser: null,
      cmApi: cmApi,
      loading: false,
      loadingError: false,
      calendar: null,
      slotFormOpen: false,
      defaultSlotFormOpen: false,
      selectedDefaultSlot: null,
      selectedSlot: null,
      fromDate: null,
      toDate: null,
      slots: [],
      hours: [
        0,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23
      ],
      slotFilters: null,
      slotFilterOpen: false,
      massModalSlots: null,
      dummyCalendarData: {
        _id: "be01508b-18b4-4d30-8412-00d08ceec90f",
        requiresPermission: false,
        defaultSlotsDaysBefore: 14,
        defaultSlotsExcludedDates: [],
        name: "Dummy Calendar",
        metadata: {
          color: "#73D8FF",
          aboveAddressText: "Anzeige Infotext Oberhalb",
          addressText: null,
          belowAddressText: "Anzeige Infotext Unterhalb",
          customFieldPermissions: null,
          isInactive: false
        },
        ownerId: "5af2a1f9-0cbe-4158-b53f-ae36b6c4ec7c",
        creationTimeStamp: "2021-06-23 06:13:16.839Z",
        updateTimeStamp: "2021-06-23 06:13:16.839Z",
        _v: 0
      },
      dummySlots: [
        {
          _id: "924f0f9c-3e28-472c-bccc-24ae089d8853",
          free: true,
          calendar: "be01508b-18b4-4d30-8412-00d08ceec90f",
          time: "12:00",
          endTime: "13:00",
          date: "2021-06-27",
          endDate: "2021-06-27",
          metadata: {
            slotType: "conversation",
            followupType: "sonnestPossible",
            extraDescription: "Extra Beschreibungstext",
            massUuid: "5f9612c4-ac9a-4130-b6cf-e1ebc2ea658f"
          },
          creationTimeStamp: "2021-06-23 06:20:42.806Z",
          updateTimeStamp: "2021-06-23 06:20:42.806Z"
        },
        {
          _id: "0f304172-de2e-494d-a4dc-5f39a87ac150",
          free: true,
          calendar: "be01508b-18b4-4d30-8412-00d08ceec90f",
          time: "13:00",
          endTime: "14:00",
          date: "2021-06-27",
          endDate: "2021-06-27",
          metadata: {
            slotType: "conversation",
            followupType: "sonnestPossible",
            extraDescription: "Extra Beschreibungstext",
            massUuid: "5f9612c4-ac9a-4130-b6cf-e1ebc2ea658f"
          },
          creationTimeStamp: "2021-06-23 06:20:42.844Z",
          updateTimeStamp: "2021-06-23 06:20:42.844Z"
        }
      ]
    };
  },
  computed: {
    ...mapGetters("users", ["getCurrentUser"]),
    days() {
      const days = [];
      if (this.fromDate && this.toDate) {
        const end = this.toDate.clone().endOf("day");
        const iDate = this.fromDate.clone();
        while (iDate.isBefore(end)) {
          days.push(iDate.clone());
          iDate.add(1, "day");
        }
      }
      return days;
    },
    rangeSelectorStyles() {
      if (this.withFloatingRangeSelector === true) {
        return {
          position: "absolute",
          top: "-30px"
        };
      }
      return {};
    },
    hasFilters() {
      if (this.slotFilters) {
        if (this.slotFilters.types) {
          if (this.slotFilters.types.length) {
            return true;
          }
        }
        if (this.slotFilters.free !== null) {
          return true;
        }
        if (this.slotFilters.vaccine !== null) {
          return true;
        }
        if (this.slotFilters.testType !== null) {
          return true;
        }
        if (this.slotFilters.isFollowup !== null) {
          return true;
        }
      }
      return false;
    },
    filterPayload() {
      const filters = {};
      if (this.slotFilters) {
        if (this.slotFilters.types) {
          if (this.slotFilters.types.length) {
            const keys = [];
            const values = [];
            for (const type of this.slotFilters.types) {
              keys.push("slotType");
              values.push(type);
            }
            filters.metadataFilters = filters.metadataFilters || [];
            filters.metadataFilters.push({
              type: "EQ",
              key: keys,
              value: values
            });
          }
        }
        if (this.slotFilters.free !== null) {
          filters.free = this.slotFilters.free;
        }
        if (this.slotFilters.vaccine !== null) {
          filters.metadataFilters = filters.metadataFilters || [];
          filters.metadataFilters.push({
            type: "EQ",
            key: "vaccine",
            value: this.slotFilters.vaccine
          });
        }
        if (this.slotFilters.testType !== null) {
          filters.metadataFilters = filters.metadataFilters || [];
          filters.metadataFilters.push({
            type: "EQ",
            key: "testType",
            value: this.slotFilters.testType
          });
        }
        if (this.slotFilters.isFollowup !== null) {
          filters.metadataFilters = filters.metadataFilters || [];
          filters.metadataFilters.push({
            type: "EQ",
            key: "isFollowup",
            value: this.slotFilters.isFollowup
          });
        }
      }
      return filters;
    }
  },
  methods: {
    formatCalendarSlot(day) {
      if (
        moment().format("YYYY-MM-DD") === day.format("YYYY-MM-DD") &&
        (this.isTabletScreenSize || this.isMobileScreenSize)
      ) {
        if (this.calendarMode === "week") {
          return "cm-calendar-day-th cm-calendar-day-max-width";
        } else {
          return "cm-calendar-day-th";
        }
      } else {
        if (this.calendarMode === "week") {
          return "cm-calendar-day-max-width";
        } else {
          return "";
        }
      }
    },
    formatWeekdays(day) {
      let weekday = "";
      switch (moment(day).format("dd")) {
        case "Mo":
          weekday = "(Mo)";
          break;
        case "Tu":
          weekday = "(Di)";
          break;
        case "Di":
          weekday = "(Di)";
          break;
        case "We":
          weekday = "(Mi)";
          break;
        case "Mi":
          weekday = "(Mi)";
          break;
        case "Th":
          weekday = "(Do)";
          break;
        case "Do":
          weekday = "(Do)";
          break;
        case "Fr":
          weekday = "(Fr)";
          break;
        case "Sa":
          weekday = "(Sa)";
          break;
        case "Su":
          weekday = "(So)";
          break;
        case "So":
          weekday = "(So)";
          break;
      }
      return weekday;
    },
    freeSlots(slots) {
      return slots.filter(slot => slot.free === true);
    },
    takenSlots(slots) {
      return slots.filter(slot => slot.free === false);
    },
    aggregateSlots(slots) {
      const a = [];
      for (const slot of slots) {
        const dateTimeId = `${slot.date} ${slot.time} ${slot.endDate} ${slot.endTime}`;
        const prev = a.find(el => el.dateTimeId === dateTimeId);
        if (prev) {
          prev.slots.push(slot);
        } else {
          a.push({
            dateTimeId,
            slots: [slot]
          });
        }
      }
      a.sort((x, y) => {
        if (x.dateTimeId > y.dateTimeId) {
          return 1;
        }
        if (x.dateTimeId < y.dateTimeId) {
          return -1;
        }
        return 0;
      });
      return a;
    },
    getFilteredSlots(day, fromTime = null, toTime = null) {
      const dayStr = day.format("YYYY-MM-DD");
      return this.slots.filter(slot => {
        if (slot.date === dayStr) {
          if (fromTime) {
            if (slot.time < fromTime) {
              return false;
            }
          }
          if (toTime) {
            if (slot.time > toTime) {
              return false;
            }
          }
          return true;
        }
        return false;
      });
    },
    openMassModal(day, fromTime = null, toTime = null) {
      this.massModalSlots = this.getFilteredSlots(day, fromTime, toTime);
      this.$refs["massModal"].show();
    },
    formatHourNumber(nbr, minute = "00") {
      if (nbr < 10) {
        return `0${nbr}:${minute}`;
      }
      return `${nbr}:${minute}`;
    },
    openSlotForm(day, slot = null) {
      this.$refs["slotFormModal"].show();
      this.slotFormOpen = day.format("YYYY-MM-DD");
      this.selectedSlot = slot;
    },
    openDefaultSlotForm(day, defaultSlot = null) {
      this.$refs["defaultSlotFromModal"].show();
      this.defaultSlotFormOpen = day.format("YYYY-MM-DD");
      this.selectedDefaultSlot = defaultSlot;
    },
    defaultSlotsAddedOrSaved() {
      this.$refs["defaultSlotFromModal"].hide();
      this.slotFormOpen = null;
      this.selectedSlot = null;
      this.loadSlots();
    },
    slotsAddedOrSaved() {
      this.$refs["slotFormModal"].hide();
      this.slotFormOpen = null;
      this.selectedSlot = null;
      this.loadSlots();
    },
    loadSlots() {
      this.slots = [];
      this.loading = true;
      this.loadingError = false;
      cmApi.slot
        .getAllTimeSlots(this.currentUser._id)
        .then(res => {
          this.loading = false;
          this.loadingError = false;
          const tempSlots = [];
          const timeSlots = res.data.data;
          timeSlots.forEach(slot => {
            const utcSlot = convertToLocal(
              slot.date,
              slot.endDate,
              slot.time,
              slot.endTime
            );
            const payload = {
              date: utcSlot.date,
              endDate: utcSlot.endDate,
              time: utcSlot.time,
              endTime: utcSlot.endTime,
              free: slot.free,
              _id: slot._id,
              metadata: slot.metadata
            };
            tempSlots.push(payload);
          });
          this.slots = tempSlots;
        })
        .catch(err => {
          this.loadingError = true;
          this.loading = false;
        });
    },
    prevPeriod() {
      const days = this.calendarMode === "week" ? 7 : 1;
      this.fromDate = this.fromDate.clone().subtract(days, "days");
      this.toDate = this.toDate.clone().subtract(days, "days");
      this.loadSlots();
    },
    nextPeriod() {
      const days = this.calendarMode === "week" ? 7 : 1;
      this.fromDate = this.fromDate.clone().add(days, "days");
      this.toDate = this.toDate.clone().add(days, "days");
      this.loadSlots();
    }
  },
  created() {
    const baseDate = this.forDate ? moment(this.forDate) : moment();
    if (this.calendarMode === "week") {
      baseDate.startOf("isoWeek");
      this.fromDate = baseDate.clone();
      this.toDate = baseDate.clone().add(6, "days");
    } else {
      this.fromDate = baseDate.clone();
      this.toDate = baseDate.clone();
    }

    if (this.getCurrentUser) {
      this.currentUser = this.getCurrentUser;
    }
    this.calendar = this.theCalendar;
    this.loadSlots();
  }
};
</script>

<style scoped></style>
