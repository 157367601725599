var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_vm._m(0)]):(_vm.loadingError)?_c('div',{staticClass:"cm-loading-error"},[_vm._v(" "+_vm._s(_vm.$t("calendarModule.errors.loadingError"))+" ")]):_c('div',[_c('b-modal',{ref:"defaultSlotFromModal",attrs:{"hide-footer":"","size":"xl","title":_vm.selectedDefaultSlot
          ? _vm.$t('calendarModule.modalTitles.editDefaultSlot')
          : _vm.$t('calendarModule.modalTitles.addDefaultSlot')},on:{"hide":function($event){_vm.defaultSlotFormOpen = false}}},[(_vm.defaultSlotFormOpen)?_c('DefaultSlotForm',{attrs:{"the-calendar":_vm.calendar,"for-date":_vm.defaultSlotFormOpen,"the-default-slot":_vm.selectedDefaultSlot},on:{"slotsAddedOrSaved":_vm.defaultSlotsAddedOrSaved}}):_vm._e()],1),_c('b-modal',{ref:"slotFormModal",attrs:{"size":"xl","title":_vm.selectedSlot
          ? _vm.$t('calendarModule.modalTitles.editSlot')
          : _vm.$t('calendarModule.modalTitles.addSlot'),"hide-footer":""},on:{"hide":function($event){_vm.slotFormOpen = false}}},[(_vm.slotFormOpen)?_c('SlotForm',{attrs:{"for-date":_vm.slotFormOpen,"the-calendar":_vm.calendar,"the-slot":_vm.selectedSlot},on:{"slotsAddedOrSaved":_vm.slotsAddedOrSaved}}):_vm._e()],1),_c('b-modal',{ref:"massModal",attrs:{"size":"xl","title":_vm.$t('calendarModule.modalTitles.massSlotsActions'),"hide-footer":""},on:{"hide":function($event){_vm.massModalSlots = null}}},[(_vm.massModalSlots)?_c('MassSlotsActions',{attrs:{"slots":_vm.massModalSlots,"is-edit":true,"edit-in-list":false},on:{"slotsChanged":_vm.slotsAddedOrSaved}}):_vm._e()],1),_c('div',{staticClass:"cm-range-selector"},[_c('div',{staticClass:"cm-range-selector-position"},[_c('div',{staticClass:"cm-range-selector-inner"},[_c('div',{staticClass:"cm-action-icon",on:{"click":_vm.prevPeriod}},[_c('b-icon-arrow-left-circle-fill')],1),(_vm.calendarMode === 'week')?_c('div',{staticClass:"cm-range-selector-text"},[_vm._v(" "+_vm._s(_vm.fromDate.format("DD.MM"))+" "+_vm._s(_vm.formatWeekdays(_vm.fromDate))+" - "+_vm._s(_vm.toDate.format("DD.MM."))+" "+_vm._s(_vm.formatWeekdays(_vm.toDate))+" ")]):_c('div',{staticClass:"cm-range-selector-text"},[_vm._v(" "+_vm._s(_vm.fromDate.format("DD.MM."))+" "+_vm._s(_vm.formatWeekdays(_vm.fromDate))+" ")]),_c('div',{staticClass:"cm-action-icon",on:{"click":_vm.nextPeriod}},[_c('b-icon-arrow-right-circle-fill')],1)])])]),_c('div',{staticClass:"cm-calendar-hour"},[_c('div',{staticClass:"cm-calendar-pre"},[_c('div',{staticClass:"cm-calendar-pre-inner"},[_vm._v(" "+_vm._s(_vm.$t("calendarModule.calendarForm.texts.day"))+" ")])]),_vm._l((_vm.days),function(day){return _c('div',{key:day.format(),staticClass:"cm-calendar-day",class:_vm.formatCalendarSlot(day)},[(
            _vm.moment().format('YYYY-MM-DD') === day.format('YYYY-MM-DD') &&
              !_vm.isTabletScreenSize &&
              !_vm.isMobileScreenSize
          )?_c('div',{staticClass:"currentDayMark"}):_vm._e(),_c('div',[_vm._v(_vm._s(day.format("DD.MM."))+" "+_vm._s(_vm.formatWeekdays(day)))]),(!_vm.isTabletScreenSize && !_vm.isMobileScreenSize)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("calendarModule.slotForm.labels.booked"))+": "+_vm._s(_vm.takenSlots(_vm.getFilteredSlots(day)).length)+"/"+_vm._s(_vm.getFilteredSlots(day).length)+" ")]):_vm._e()])})],2),_vm._l((_vm.hours),function(hour){return _c('div',{key:'hour' + hour,staticClass:"cm-calendar-hour"},[_c('div',{staticClass:"mt-1"},[_c('div',{staticClass:"cm-calendar-pre-inner"},[_vm._v(" "+_vm._s(_vm.formatHourNumber(hour))+" ")])]),_vm._l((_vm.days),function(day){return _c('div',{key:hour + day.format(),staticClass:"cm-calendar-day"},_vm._l((_vm.aggregateSlots(
            _vm.getFilteredSlots(
              day,
              _vm.formatHourNumber(hour),
              _vm.formatHourNumber(hour, '59')
            )
          )),function(aggregatedSlot){return _c('b-badge',{key:aggregatedSlot.dateTimeId + 'aggregatedSlot',staticClass:"font- size-very-small mr-1 ml-1 cm-aggregated-slots badge-cal-padding",class:_vm.takenSlots(aggregatedSlot.slots).length > 0
              ? 'bg-warning'
              : (aggregatedSlot.slots[0].metadata.isFollowupSlot ? 'bg-dark' : 'bg-success'),on:{"click":() =>
              _vm.openMassModal(
                day,
                aggregatedSlot.slots[0].time,
                aggregatedSlot.slots[0].time
              )}},[(_vm.isMobileScreenSize || _vm.isModalMobileScreenSize)?_c('img',{class:`p-2 bg-white ${
                aggregatedSlot.slots[0].metadata.type === 'gom'
                  ? (aggregatedSlot.slots[0].metadata.isFollowupSlot ? 'feathericons-link' : 'feathericons-users')
                  : 'feathericons-user'
              }`}):_c('span',[_c('img',{class:`p-2 bg-white ${
                  aggregatedSlot.slots[0].metadata.type === 'gom'
                    ? (aggregatedSlot.slots[0].metadata.isFollowupSlot ? 'feathericons-link' : 'feathericons-users')
                    : 'feathericons-user'
                }`}),_vm._v(" "+_vm._s(aggregatedSlot.slots[0].time)+" - "+_vm._s(aggregatedSlot.slots[0].endTime))])])}),1)})],2)})],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-between mx-5 mt-3 mb-5"},[_c('div',{staticClass:"spinner-border text-success align-self-center loader-lg"})])
}]

export { render, staticRenderFns }